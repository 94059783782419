import store from '@/store'

const adminguard = (to, from, next) => {
    if(localStorage.getItem('callcenterauthtoken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.role == 'agent' ){
        return next()
    }else{
        return next({ name: 'login' })
    }
};


let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/main.vue"),
        title:'',

          children: [

            {
              path: "/",
              name: "welcome",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/dash.vue"),
              meta: {
                requiresAuth: true,
              title: 'Agent Dashboard'
              }
            },

            {
              path: "/analytics",
              name: "analytics",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/analytics/main.vue"),
              meta: {
                requiresAuth: true,
              title: 'Agent Analytics'
              }
            },

            //active calls
            {
              path: "/call-logs",
              name: "call_logs",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/calls/call_logs.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Call History'
              }
            },

          
     
            //settings
            {
              path: "/settings",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/settings/main.vue"),
              children:[
                {
                  path: "profile",
                  name: "user_profile",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/profile.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Mass Communications'
                  }
                },

                {
                  path: "calls",
                  name: "call_settings",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/call_settings.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Mass Communications'
                  }
                },

                {
                  path: "system",
                  name: "system_settings",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/system_settings.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Mass Communications'
                  }
                },

                {
                  path: "updates",
                  name: "system_updates",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/system_updates.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Mass Communications'
                  }
                },

              ]
             
            },



            
        ]
    }
]
export default routes;