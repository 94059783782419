export const namespaced = true
import store from '@/store'
import router from '@/router'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        authuser :'',
        authlocation : '',
        isAuth:'',
		returnvalue:'',
        userip:'',
        clientlocation:'',
        clientdevice:'',
    },
    
    getters : {
        authuser: (state )=> state.authuser,
        authlocation: (state )=> state.authlocation,
		returnvalue: (state )=> state.returnvalue,
        isAuth: state => !!state.authuser,
        userip: (state )=> state.userip,
        clientlocation:(state )=> state.clientlocation,
        clientdevice:(state )=> state.clientdevice,
       
    },


    mutations : { 
        setauthuser :(state,authuser ) => (state.authuser = authuser), 
        setauthlocation :(state,authlocation ) => (state.authlocation = authlocation), 
        setisAuth :(state,isAuth ) => (state.isAuth = isAuth), 
        setreturnvalue :(state,returnvalue ) => (state.returnvalue = returnvalue),
        setuserip :(state,userip ) => (state.userip = userip),
        setclientlocation :(state,clientlocation ) => (state.clientlocation = clientlocation),
        setclientdevice :(state,clientdevice ) => (state.clientdevice = clientdevice),
    },

    actions : {


        getuseripaddress({ commit }){
            fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    commit('setuserip', ip)
                });
        },

        getuserlocation({ commit, getters }){
            console.log(getters.userip)
            const url = "https://ipapi.co/"+ getters.userip +"/json/";
            const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
                a: 10,
                b: 20,
            }),
            };
            fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
                commit('setclientlocation', data)
            });
        },


        async register( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'register')
            return new Promise((resolve, reject) => {
            axios.post("/auth/agent/register", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('callcenterauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.res)
                    toast.success(response.data.msg)
                    resolve(response)
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async login( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'login')
            return new Promise((resolve, reject) => {
            axios.post("/auth/agent/login", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('callcenterauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.res)
                    toast.success(response.data.msg)
                    resolve(response)
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async forgot_password( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/auth/admin/forgot_password", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async reset_password( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/auth/admin/reset_password", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async request_otp( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/auth/admin/requestotp", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async verify_code( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'verify_code')
            return new Promise((resolve, reject) => {
            axios.post("/auth/admin/verify_code", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('callcenterauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.res)
                    toast.success(response.data.msg)
                    resolve(response)
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async getauthuser( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'loginuser')
            return new Promise((resolve, reject) => {
            axios.post("/auth/admin/authuser", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('callcenterauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.res)
                    toast.success(response.data.msg)
                    resolve(response)
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async logoutuser( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'logoutuser')
            return new Promise((resolve, reject) => {
            axios.post("/auth/admin/logout", data)
            .then(response => {	
                console.log(response);
                commit('setloader', false)
                localStorage.clear()
                commit('setauthuser', '')
                toast.success(response.data.msg)
                resolve(response)

            })
            .catch(error=> {
                commit('setloader', false)
                localStorage.removeItem('callcenterauthtoken')
                commit('setauthuser', '')
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_user_account( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_user_account')
            return new Promise((resolve, reject) => {
            axios.post("/auth/admin/updateaccount", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('callcenterauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.res)
                    toast.success(response.data.msg)
                    resolve(response)
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async updateprofileimage( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'updateprofileimage')
            return new Promise((resolve, reject) => {
            axios.post("/auth/admin/updateprofilepic", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('callcenterauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.res)
                    toast.success(response.data.msg)
                    resolve(response)
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async changepassword( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'changepassword')
            return new Promise((resolve, reject) => {
            axios.post("/auth/admin/updatepassword", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    localStorage.setItem('callcenterauthtoken'+ response.data.res.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.res)
                    toast.success(response.data.msg)
                    resolve(response)
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async deleteuseraccount( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'deleteuseraccount')
            return new Promise((resolve, reject) => {
            axios.post("/auth/admin/deleteaccount", data)
            .then(response => {	
                commit('setloader', false)
                localStorage.clear()
                commit('setauthuser', '')
                toast.success(response.data.msg)
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}













